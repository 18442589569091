export async function createUser(
  token: string,
  server: string
): Promise<boolean> {
  try {
    const token_filtered = token.replaceAll("-", "");
    const response = await fetch(server + "users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: token_filtered,
        password: token_filtered,
      }),
    });

    if (response.status === 409) {
      throw new Error("Username in use");
    }
    return response.ok;
  } catch {
    return false;
  }
}
